import { useState } from 'react'
  
const useInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(event.target.value)
  }

  return {
    value,
    onChange: handleChange
  }    
}

export default useInput