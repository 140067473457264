import React, { useRef } from "react"
import styled from "styled-components"

import useWebsite from "../states/context/websiteContext"
import useOnScreen from "../utils/hooks/useOnScreen"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionTitle from "../components/titles/sectiontitle"
import Form from "../components/form/form"
import { Language } from "../states/context/websiteReducer"

const ContactPage = () => {
  const { language } = useWebsite()
  const isEnglish = language == Language.en
  const ref = useRef<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(ref);

  return (
    <Layout>
      <SEO lang={language} title="Contact" />
      <Wrapper ref={ref} className={isVisible ? "animate__animated animate__fadeIn animate__delay-0.5s" : undefined}>
        <div>
          <SectionTitle title={isEnglish ? "Contact Us" : "Contactez-nous"} />
          <p>
            {isEnglish ? "For general inquiries or to get a quote, contact us by using the form on this page. For urgent matters, please contact us by phone at" : "Pour des questions d'ordre général ou pour obtenir un devis, contactez-nous en utilisant le formulaire de cette page. Pour les questions urgentes, veuillez nous contacter par téléphone au"}{" "}
            <span>+1-514-432-7446</span>.
          </p>
        </div>
        <Form />
      </Wrapper>
    </Layout>
  )
}

export default ContactPage

const Wrapper = styled.div`
  max-width: 1140px;
  display: grid;
  margin: 0 auto;
  padding: 100px 0;
  justify-content: left;
  gap: 100px;

  p {
    line-height: 30px;
    letter-spacing: 0.1em;
    max-width: 550px;
  }

  span {
    font-weight: bold;
  }

  @media (max-width: 1220px) {
    padding: 40px;
  }
`
