import React from "react"
import styled from "styled-components"

interface Props {
  placeholder: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  required?: boolean
}

const InputField = (props: Props) => {
  const { placeholder, value, onChange, required = true } = props

  return <Input placeholder={placeholder} required={required} value={value} onChange={onChange} className="formInputs" />
}

export default InputField

const Input = styled.input`
  border: none;
  border-bottom: 2px solid #005085;
  width: 300px;
  padding: 10px;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 1; /* Firefox */
    text-transform: uppercase;
    color: #a49d9d;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    text-transform: uppercase;
    color: #a49d9d;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    text-transform: uppercase;
    color: #a49d9d;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 390px) {
    width: 250px;
  }
`
