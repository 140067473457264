import React from "react"
import styled from "styled-components"
import emailjs from 'emailjs-com'

import useWebsite from "../../states/context/websiteContext"
import getFirebase from "../../utils/firebase"
import "firebase/firestore"
import useInput from "../../utils/hooks/useInput"
import BlueButton from "../buttons/blueButton"
import InputField from "./input"
import { Language } from "../../states/context/websiteReducer"

const Form = () => {
  const { language } = useWebsite()
  const isEnglish = language === Language.en
  const name = useInput("")
  const email = useInput("")
  const phone = useInput("")
  const message = useInput("")

  const submitForm = async () => {
    event.preventDefault()
    // EmailJS
    const serviceId = process.env.GATSBY_EMAILJS_SERVICE_ID
    const templateId = process.env.GATSBY_EMAILJS_TEMPLATE_ID
    const userId = process.env.GATSBY_EMAILJS_USERID

    emailjs
      .send(
        serviceId,
        templateId,
        {
          name: name.value,
          email : email.value,
          message: message.value
        },
        userId
      )
      .then(response => {
        if (response.status === 200) {
          {isEnglish ? alert("Thank you for your message! We'll reply as soon as possible.") : alert("Merci pour votre message! Nous vous recontacterons dans les plus brefs délais.")}
        }
      })
      // Handle errors here however you like
      .catch(err => {
        console.error("Failed to send message. Error: ", err)
      })

    // Firebase
    const firebase = getFirebase()

    if (!firebase) return

    try {
      const db = firebase.firestore()
      await db.collection("formSubmissions").add({
        name: name.value,
        email: email.value,
        phone: phone.value,
        message: message.value,
        date: Date(),
      })
    } catch (error) {
      console.log("error", error)
    }

    const inputs = (document.querySelectorAll(".formInputs") as unknown) as [
      HTMLInputElement
    ]
    inputs.forEach(element => {
      element.value = ""
    })
    const textAreaInput = document.querySelector(
      ".textArea"
    ) as HTMLTextAreaElement
    textAreaInput.value = ""
  }

  return (
    <Wrapper onSubmit={() => submitForm()}>
      <InputsWrapper>
        <InputField placeholder={isEnglish ? "Your name *" : "Votre nom *"} {...name} />
        <InputField placeholder={isEnglish ? "Your email address *" : "Votre adresse courriel *"} {...email} />
        <InputField placeholder={isEnglish ? "Your phone number *" : "Votre numéro de téléphone *"} {...phone} required={false} />
      </InputsWrapper>
      <TextArea
        placeholder={isEnglish ? "Your message *" : "Votre message *"}
        {...message}
        required
        className="textArea"
      />
      <BlueButton text={isEnglish ? "Send" : "Envoyer"} type="submit" />
    </Wrapper>
  )
}

export default Form

const Wrapper = styled.form`
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: 900px;
  gap: 40px;
  margin-bottom: 20px;

  button {
    width: 200px;
  }

  @media (max-width: 1220px) {
    grid-template-columns: auto;
    width: 600px;
  }

  @media (max-width: 655px) {
    width: 350px;
  }

  @media (max-width: 390px) {
    width: 200px;
  }
`

const InputsWrapper = styled.div`
  display: grid;
  gap: 20px;
`

const TextArea = styled.textarea`
  border: 2px solid #005085;
  padding: 10px;
  font-family: "Heebo", sans-serif;

  ::placeholder {
    color: #a49d9d;
    font-family: "Heebo", sans-serif;
    text-transform: uppercase;
  }

  :focus {
    outline: none;
  }
`
